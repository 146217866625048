/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import TikTokLayout from '@layouts/TikTokLayout'
import TikTokPageContainer from '@containers/TikTokPage/TikTokPageContainer'
import avaliableLangs from '@localization/avaliableLangs'

const IndexPage = ({ location }) => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>The TikTok Bible for Influencers & Creators</title>
        <link
          rel='canonical'
          href='https://reports.onlinepunks.com/en/tiktokCreators/'
        />
        <meta
          property='og:url'
          content='https://reports.onlinepunks.com/en/tiktokCreators/'
        />
        <meta
          property='og:title'
          content='The TikTok Bible for Influencers & Creators'
        />
        <meta
          property='og:description'
          content='TikTok e-book for Creators, presented by Onlinepunks'
        />
        <meta
          property='og:image'
          content='https://reports.onlinepunks.com/tiktokBible/Footer_@1x.png'
        />
        <meta property='og:type' content='website' />
        <meta name='twitter:card' content='summary' />
        <script
          id='pixel-script-poptin'
          src='https://cdn.popt.in/pixel.js?id=df783521a0109'
          async='true'
        />
      </Helmet>
      <TikTokLayout location={location} pageLanguage={avaliableLangs.en}>
        <TikTokPageContainer />
      </TikTokLayout>
    </>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
